html, body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#renderCanvas {
  width: 100%;
  height: 100%;
  touch-action: none;
}

.loadingBackground {
  /*width: auto;
  //height: auto;*/
  background-color: #161616;
  /*overflow:auto;*/
}

.loadingElement {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
}

#logoImage{
  top: 50%;
  left: 50%;
  transform: translateX(11%);
  max-width: 80%;
  max-height: 80%;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
